import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div id="top" className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className="">
        {children}
      </main>

      <footer className="text-center py-8 xl:py-12 bg-blue">
          <p className="text-white text-base md:text-lg container m-auto">© Copyright – Mental Health Support Kits</p>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
