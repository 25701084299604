import React from "react";
import PropTypes from "prop-types";
import {AnchorLink} from "gatsby-plugin-anchor-links";

function Button(props) {
  return (<div>
    <AnchorLink to={props.link} className="bg-yellow border-0 py-2 xl:py-3 px-4 xl:px-6 inline-block m-auto rounded-md font-medium text-lg text-blue hover:bg-teal hover:text-white">{props.label}</AnchorLink>
  </div>)
}

Button.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string
};

export default Button;
