import {graphql, useStaticQuery} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import React, {useState} from "react";
import Button from "../components/button";
import imgLogo from "../images/Logo.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const navItems = [
    {
      route: `/#why`,
      title: `Why a kit?`
    }, {
      route: `/#kit`,
      title: `What's included`
    }, {
      route: `/#benefits`,
      title: `Benefits`
    }, {
      route: `/#authors`,
      title: `Behind the kit`
    }, {
      route: `/#services`,
      title: `Services`
    }
  ];
  const {site} = useStaticQuery(graphql `
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (<header className="lg:fixed lg:flex lg:w-full lg:justify-between lg:items-center lg:p-6 xl:p-8 lg:bg-white lg:z-50">
    <div className="flex justify-between fixed top-0 left-0 right-0 lg:static z-40 p-4 lg:p-0 bg-white">
      <AnchorLink to="/#top" className="border-b-0">
        <h1 className="no-underline text-xl font-bold tracking-tight">
          <img src={imgLogo} alt={site.siteMetadata.title}/>
        </h1>
      </AnchorLink>

      <button className="block px-3 py-2 lg:hidden" onClick={() => toggleExpansion(!isExpanded)}>
        {
          isExpanded
            ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 fill-current text-blue">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            : <svg className="w-6 h-6 fill-current text-blue" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
              </svg>
        }
      </button>
    </div>
    {console.log("expanded", isExpanded)}
    <nav className={`primary-nav ${isExpanded
        ? `block`
        : `hidden`} px-6 fixed top-0 left-0 right-0 bottom-0 z-30 flex items-center bg-yellow-light lg:bg-transparent lg:static lg:px-0  lg:block `}>
      <ul className="space-y-4 lg:space-y-0 lg:space-x-2 xl:space-x-3">
        {
          navItems.map((link) => (<li className="lg:inline-block" key={link.title} onClick={() => toggleExpansion(!isExpanded)}>
            <AnchorLink className="text-2xl no-underline font-medium text-blue border-b-0 lg:border-b-2 lg:border-teal lg:hover:border-yellow lg:inline-block lg:text-lg xl:text-xl" key={link.title} to={link.route}>
              {link.title}
            </AnchorLink>
          </li>))
        }
      </ul>
    </nav>
    <div className="hidden lg:block"><Button link="/#contact" label="Enquire"/></div>

  </header>);
}

export default Header;
